import React, { useState, useEffect, useContext, memo } from 'react';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import Link from 'next/link'
import Image from 'next/image'
import { useCallback } from 'react';
import getBlog from '../../firebase/data/blog';
import styles from './blog.module.css';

const Post = memo(function Post({ post }) {
    return (
        <div className="grid gap-6" key={post.id}>
            <div className={styles.imageContainer}>
                <Link href={`/${post.slug}`}>
                    <Image
                        alt={post.featured_alt}
                        title={post.featured_alt}
                        className={`${styles.postThumb} rounded-lg`}
                        width={600}
                        height={400}
                        src={post.featured_url}                    
                    />
                </Link>
            </div>
            <div className="space-y-4">
                <div className="space-y-2">
                    <Link className={`${styles.blog} ${styles.postTitle} text-2xl font-semibold`} href={`/${post.slug}`}>
                        {post.title}
                    </Link>
                    <p className={`${styles.excerpt} text-gray-500 dark:text-gray-400`}>
                        {post.excerpt}
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    <Image
                        src="/images/profile.webp"
                        alt="Web Development Services"
                        title="Web Development Services"
                        className="rounded-full"
                        width={48}
                        height={48}
                        style={{
                            objectFit: "cover",
                        }}
                    />
                    <div className="text-sm">
                        <p className="font-semibold">Bloggers Network</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">{format(new Date(post.created_at), 'dd MMMM yyyy', { locale: enUS })}</p>
                    </div>
                </div>
                <ul className="flex flex-wrap gap-2">
                    {post.category.map((category, index, array) => (
                        <li key={index} className="text-sm py-1">
                            <a href="#" className="underline font-medium" style={{ textDecorationThickness: '1px', textUnderlineOffset: '3px' }}>
                                {category.trim()}
                            </a>
                            {index < array.length - 1 && ', '}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});

export default function Blog() {
    const [posts, setPosts] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);

    const getPosts = useCallback(async () => {
        const now = Date.now();
        const oneDay = 24 * 60 * 60 * 1000; // 24 horas en un día

        // Si no hemos actualizado en la última hora, entonces actualizamos
        if (!lastUpdate || now - lastUpdate > oneDay) {
            const { result, error } = await getBlog();
            if (error) {
                //console.error("Error fetching posts: ", error);
            } else {
                setPosts(result);
                setLastUpdate(now); // actualizamos la hora de la última actualización
            }
        } else {
            //console.log("Using cached posts"); // Agregamos este mensaje
        }
    }, [lastUpdate]);

    useEffect(() => {
        getPosts();
    }, [getPosts]);

    return (
        <div className="home-blog max-w-screen-xl mx-auto py-20 mt-10">
            <h2 className="mb-3 text-center">Latest Blog News on Web Development Services</h2>
            <h3 className="text-center">Discover the latest insights, trends, and tips to elevate your knowledge and skills</h3>
            <div className="px-4 py-6 md:px-6 md:py-12 lg:py-16 mt-20">
                <div className="grid gap-6 lg:grid-cols-3 lg:gap-12">
                    {posts.slice(0, 3).map((post) => (
                        <Post key={post.id} post={post} />
                    ))}
                </div>
            </div>
        </div>
    );
}