import firebase_app from "../config";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { getFirestore, collection, query, orderBy, limit, getDocs } from "firebase/firestore";

const auth = getAuth(firebase_app);
const db = getFirestore(firebase_app);

export default async function getBlog() {
    let result = null,
        error = null;
    try {
        const q = query(collection(db, "blog"), orderBy("created_at", "desc"), limit(6));
        const querySnapshot = await getDocs(q);
        result = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
        error = e;
    }

    return { result, error };
}