import React, { useLayoutEffect } from 'react';
import styles from "./faqs.module.css";
import Image from "next/image";
import Link from 'next/link'
import FaqItem from './FaqItem';

const faqsData = [
    {
        question: "¿Por qué contratar paquetes de horas?",
        answer: "Contratar paquetes de horas es ideal para trabajos específicos de soporte técnico, personalizaciones y mantenimiento. Ofrece flexibilidad, asegura la disponibilidad de un experto cuando lo necesites y permite una mejor planificación y control de costos."
    },
    {
        question: "¿Y si necesito un sitio web nuevo o una tienda en línea?",
        answer: "En ese caso, es mejor trabajar con presupuestos cerrados. Esto asegura que el proyecto se desarrolle con un costo fijo, permitiendo una planificación financiera más precisa y garantizando que todos los requerimientos y funcionalidades se incluyan desde el principio sin sorpresas adicionales."
    },
    {
        question: "¿En cuánto tiempo recibiré los cambios?",
        answer: "El tiempo de entrega depende de la cola de trabajo, pero generalmente no supera los dos días. Además, puede variar según el número de horas contratadas y la complejidad de las modificaciones solicitadas."
    },
    {
        question: "¿Qué sucede si contrato más horas de las debidas?",
        answer: "Si contratas más horas de las pactadas inicialmente, las horas restantes se acumularán como crédito para futuros ajustes o nuevas funcionalidades en tu proyecto."
    },
    {
        question: "¿Cómo contrato un paquete de horas?",
        answer: "Para contratar un paquete de horas, simplemente selecciona el paquete que mejor se adapte a tus necesidades en la sección \"Precios\". Una vez elegido, sigue las instrucciones de compra."
    },
    {
        question: "¿Cuáles son los lenguajes de programación que puedo requerir para trabajos?",
        answer: "En Desarrollo Web Profesional trabajamos con PHP, JavaScript, HTML, CSS. Si necesitas un lenguaje específico, no dudes en consultarnos."
    },
    {
        question: "¿Con qué Frameworks y CMS puedo trabajar?",
        answer: "En Desarrollo Web Profesional trabajamos con Laravel, Wordpress y Prestashop."
    },
    {
        question: "Necesito cotizar un proyecto específico, ¿cómo lo hago?",
        answer: "Cuéntanos todo sobre tu proyecto a través de nuestro formulario de contacto o escríbenos a soporte@desarrollowebprofesional.com. Juntos crearemos una solución a medida y te enviaremos un presupuesto detallado."
    }
];

const Faqs = () => {
    return (
        <div id="faqs" className={`${styles.faqs} mt-20 gap-12`}>
            <Image src="/images/line.svg" alt="Desarrollo Web Profesional" width={15} height={150} className="mx-auto mb-20"/>
            <h2 className="mb-16 text-center">Preguntas Frecuentes</h2>
            <div className="flex flex-col md:flex-row justify-between gap-20">
                <div>
                    {faqsData.map((faq, index) => (
                        <FaqItem key={index} question={faq.question} answer={faq.answer} isOpen={index === 0}/>
                    ))}
                </div>
                <div className="bg-white rounded-2xl p-10 pb-12 text-center h-auto">
                    <h3>¡<b>Disfruta 1 Hora</b> de prueba <b>Gratis</b><br/> para nuevos usuarios!</h3>
                    <Image src="/images/free-hour.webp" width={100} height={193} alt="Soporte Técnico Gratis" className="mx-auto mt-10"/>
                    <p className="mt-5 mb-10"><b>¡No te quedes con la duda!</b> Prueba nuestro servicio sin compromiso y
                        descubre por qué somos la mejor opción para tu negocio.</p>
                    <Link href="#pricing" className={`${styles.btnFaqs} w-[300px] mx-auto text-center mt-10 mb-10`}>
                        ¡Quiero mi hora gratis!
                    </Link>
                    <p className={`${styles.faqsEmail}`}>
                        ¿Prefieres escribir? <Link href="mailto:soporte@desarrollowebprofesional.com"
                                                   className="font-bold">soporte@desarrollowebprofesional.com</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Faqs;
