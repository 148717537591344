import React, {useEffect} from 'react';
import Layout from '../components/Layout'
import Slide from '../components/Slideshow/Slide'
import Blog from '../components/Home/Blog'
import Testimonials from '../components/Home/Testimonials';
import Link from 'next/link'
import Footer from "../components/Footer";
import Benefits from "../components/Home/Benefits";
import Services from "../components/Home/Services";
import Maintenance from "../components/Home/Maintenance";
import Faqs from "../components/Home/Faqs";
import Plans from "../components/Home/Plans";
import Clients from "../components/Home/Clients";

export default function Home() {
    return (
        <Layout
            title="Desarrollo Web Profesional"
            metaTitle="Desarrollo Web Profesional"
            metaDescription="Transforma tu presencia en línea con nuestros Servicios de Desarrollo Web. Diseño experto, optimización y experiencias fluidas a tu medida."
        >
            <div className="webapp mx-auto">
                <Slide/>

                <div className="container">
                    <div className="content">
                        <Services />

                        <Clients />

                        <Maintenance />

                        <Benefits />

                        <Testimonials />

                        <Faqs />

                        <Plans />
                    </div>
                </div>

                <section id='footer-block' className='text-center p-20'>
                    <h5 className='font-extrabold mb-5'>¡PONGÁMONOS A TRABAJAR!</h5>
                    <h3>
                        Estamos ansiosos por escuchar los desafíos que enfrentan tu marca y tu negocio, sin importar si no estás seguro de cuál debería ser tu próximo paso.
                    </h3>
                </section>

                <Footer isPricingPage={true} />

            </div>
        </Layout>
    )
}
