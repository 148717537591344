import React, { useLayoutEffect } from 'react';
import { useState, useEffect } from 'react';
import styles from './plans.module.css';
import Image from 'next/image'
import Link from 'next/link'
import { RiArrowRightWideFill } from "react-icons/ri";
import { IoTimeOutline } from "react-icons/io5";

const Plans = () => {
    const packages = [
        {
            hours: 1,
            description: 'Descubre cómo nuestro Paquete de Servicios Avanzados de 1 Hora puede beneficiarte y mejorar tu proyecto.'
        },
        {
            hours: 5,
            description: 'Explora cómo nuestro Paquete de Servicios Avanzados de 5 Horas puede impulsar tu proyecto y alcanzar tus objetivos.'
        },
        {
            hours: 10,
            description: 'Aprovecha al máximo nuestro Paquete de Servicios Avanzados de 10 Horas para llevar tu proyecto al siguiente nivel y asegurar su éxito.'
        },
        {
            hours: 50,
            description: 'Descubre el potencial de nuestro Paquete de Servicios Avanzados de 50 Horas para transformar tu proyecto y alcanzar resultados extraordinarios.'
        }
    ];

    const [selectedPackage, setSelectedPackage] = useState(packages[0]);
    const [location, setLocation] = useState('');

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        
        if (userLanguage.startsWith('es-ES')) {
            setLocation('Mexico');
        } else {
            setLocation('EEUU');
        }
    }, []);

    const handleClick = (pkg, event) => {
        event.preventDefault();
        setSelectedPackage(pkg);

        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const pricePlansElement = document.getElementById('pricePlans');
            if (pricePlansElement) {
                const targetPosition = pricePlansElement.getBoundingClientRect().top + window.pageYOffset;
                const startPosition = window.pageYOffset;
                const distance = targetPosition - startPosition;
                const duration = 1000; // Duración de la animación en milisegundos
                let start = null;

                const step = (timestamp) => {
                    if (!start) start = timestamp;
                    const progress = timestamp - start;
                    const progressPercentage = Math.min(progress / duration, 1);
                    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                    const easedProgress = easeInOutQuad(progressPercentage);
                    window.scrollTo(0, startPosition + distance * easedProgress);
                    if (progress < duration) {
                        window.requestAnimationFrame(step);
                    }
                };

                window.requestAnimationFrame(step);
            }
        }
    };

    return (
        <div id="pricing" className={`${styles.testimonials} flex flex-col mt-20 gap-12`}>
            <Image src="/images/line.svg" alt="test" width={15} height={150} className="mx-auto" />

            <div className={`${styles.pricingContent} flex flex-col md:flex-row mt-20 gap-12`}>
                <div className="w-full md:w-1/2 p-10 rounded-xl">
                    <h2 className="mb-2">Paquetes<br/> de Horas</h2>
                    <p className={`${styles.smPricingTitle} mb-4 p-1 px-5 rounded-md mb-5 text-sm w-fit text-black bg-emerald-300`}>Desarrollo
                        web que impulsa tu negocio</p>
                    <div className={`${styles.pricingHours} flex flex-col mt-10`}>
                        {packages.map((pkg, index) => (
                            <div 
                                key={index} 
                                className={`${styles.pricingItem} p-4 rounded-sm mb-2 ${selectedPackage.hours === pkg.hours ? styles.selectedPackage : ''}`} 
                                onClick={(event) => handleClick(pkg, event)}
                            >
                                <a href="#" onClick={(event) => event.preventDefault()}>
                                    <div className="flex flex-row items-center">
                                        <div>
                                            <h4 className="text-2xl mb-1"><span className="text-emerald-300">{pkg.hours}</span> Horas</h4>
                                            <p>{pkg.description}</p>
                                        </div>
                                        <div className="text-2xl">
                                            <RiArrowRightWideFill className="fill-emerald-300"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div id="pricePlans" className="w-full md:w-1/2 bg-white rounded-xl p-10">
                    {selectedPackage ? (
                        <div className='flex flex-col justify-between h-full'>
                            <div>
                                <h4 className="text-5xl mb-3">Servicios Avanzados</h4>
                                <p>{selectedPackage.description}</p>
                                
                                <div className="p-4 bg-gray-800 rounded-lg shadow-md mt-5">
                                    <p className="text-lg font-semibold text-gray-200">
                                        Precio:
                                        <span className="text-emerald-300 ml-1">
                                            {location === 'Mexico'
                                                ? `${(selectedPackage.hours * 250).toFixed(2)}`
                                                : `${(selectedPackage.hours * 20).toFixed(2)}`}
                                        </span>
                                        <small className='ml-1'>
                                            {location === 'Mexico' ? 'MXN' : 'USD'}
                                        </small>
                                        <small className="ml-1">({selectedPackage.hours} {selectedPackage.hours === 1 ? 'hora' : 'horas'})</small>
                                    </p>
                                </div>
                        
                                <hr className='mt-10 mb-10' />
                                
                                <h5 className='text-2xl md:text-3xl mb-5'>¿Qué está incluido?</h5>
                            </div>
                            <div className='flex flex-col md:flex-row gap-10 mt-5 leading-10 justify-between'>
                                <div className='w-1/10 md:w-1/2'>
                                    <ul className='list-disc pl-5'>
                                        <li>Diseño web</li>
                                        <li>Desarrollo web</li>
                                        <li>Gestión de CMS</li>
                                        <li>Soporte técnico</li>
                                        <li>Contratación de dominios</li>
                                        <li>Asistencia técnica</li>
                                    </ul>
                                </div>
                                <div className='w-1/10 md:w-1/2'>
                                    <ul className='list-disc pl-5'>
                                        <li>Migración de datos</li>
                                        <li>Positionamiento SEO</li>
                                        <li>Redacción de contenidos</li>
                                        <li>Marketing Digital</li>
                                        <li>Contratación de Hosting</li>
                                        <li>Cursos de desarrollo web</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='gap-10 mt-auto pt-10 items-center text-center'>
                                <Link href={`/contacto/${selectedPackage.hours}`} className={`${styles.btnPackage} font-bold`}>Contáctanos <small>x</small> <span className="text-emerald-300">{selectedPackage.hours}hrs</span></Link>
                            </div>
                        </div>
                    ) : (
                        <p>Selecciona un paquete para ver los detalles.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Plans;
