import React, { useLayoutEffect } from 'react';
import styles from "./services.module.css";
import { FaWordpressSimple } from "react-icons/fa";
import { SiPrestashop } from "react-icons/si";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { MdOutlineMonitorHeart } from "react-icons/md";
import { MdHealthAndSafety } from "react-icons/md";
import { MdOutlineBackup } from "react-icons/md";


const Maintenance = () => {
    return (
        <div className={`${styles.services}`}>
            <div
                className={`${styles.headerServices} ${styles.headerMaintenance} flex flex-row justify-between items-end text-white mx-auto mt-10 relative`}>
                <div>
                    <h2 className="leading-[48px] text-[40px] font-medium text-white">
                        Mantenimiento Web<br/>
                        y Soporte Técnico
                    </h2>
                </div>
                <div className="leading-[40px]">
                    <h2 className="text-[40px] font-medium text-white">8 años</h2>
                    en soporte técnico
                </div>
            </div>
            <div className={`${styles.contentServices} flex flex-row justify-between gap-5 mt-5`}>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <FaWordpressSimple/>
                    </span>
                    <h3 className="font-bold mb-2">Wordpress</h3>
                    <p>
                        <b>Protección Avanzada Permanente:</b> Descansa tranquilo sabiendo que tu sitio WordPress está en las mejores manos. Nuestro
                        servicio de mantenimiento automatizado te garantiza actualizaciones constantes.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <SiPrestashop/>
                    </span>
                    <h3 className="font-bold mb-2">Prestashop</h3>
                    <p>
                        <b>E-Commerce:</b> Maximiza tus ventas con un sitio seguro y siempre actualizado. Nuestro servicio
                        especializado optimiza tu tienda online, agiliza los procesos de compra y te protege contra
                        fraudes.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <IoHelpBuoyOutline/>
                    </span>
                    <h3 className="font-bold mb-2">Soporte Técnico</h3>
                    <p>
                        Soporte técnico personalizado para tu negocio. Entendemos que cada tienda online es única, por
                        eso te ofrecemos un servicio a medida para resolver tus problemas específicos.
                    </p>
                </div>
            </div>
            <div className={`${styles.contentServices} flex flex-row justify-between gap-5 mt-5`}>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <MdOutlineMonitorHeart/>
                    </span>
                    <h3 className="font-bold mb-2">Monitor 24/7</h3>
                    <p>
                        Vigilancia 24/7: Tu sitio web siempre protegido. Nuestro sistema de monitoreo trabaja ininterrumpidamente para detectar y neutralizar cualquier amenaza que pueda surgir.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <MdOutlineBackup/>
                    </span>
                    <h3 className="font-bold mb-2">Backups externos</h3>
                    <p>
                        Tus datos, a salvo de cualquier imprevisto. Los backups externos son tu red de seguridad ante desastres naturales, fallos del hardware, ciberataques o errores humanos.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <MdHealthAndSafety/>
                    </span>
                    <h3 className="font-bold mb-2">Desinfección de malware</h3>
                    <p>
                        Nuestro servicio de desinfección de malware elimina completamente cualquier código malicioso de tu sitio web, asegurando su funcionamiento óptimo y la protección de tus datos.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;
