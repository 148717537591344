import React, { useLayoutEffect } from 'react';
import styles from "./clients.module.css";
import Link from "next/link";

const Clients = () => {
    return (
        <div id="clients" className={`${styles.clients} flex flex-row justify-around items-center text-white mt-10`}>
            <ul className='flex flex-row gap-10'>
                <li><Link href="https://larcabarcelona.com" target="_blank">LarcaBarcelona</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://oliverweber.com" target="_blank">OliverWeber</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://ultimainformatica.com" target="_blank">ÚltimaInformática</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://segundin.com" target="_blank">Segundín</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://todoespecias.com" target="_blank">TodoEspecias</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://licocosmetics.com" target="_blank">LicoCosmetics</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://chateohometasting.com" target="_blank">ChateoHometasting</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://solvinco.com" target="_blank">Solvinco</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://gruaslozano.com" target="_blank">GrúasLozano</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://dakadabags.com" target="_blank">DakadaBags</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://clandestinotshirt.com" target="_blank">ClandestinotShirt</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://kulturaltours.com" target="_blank">KulturalTours</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://tustours.com" target="_blank">TusTours</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://larcabarcelona.com" target="_blank">LarcaBarcelona</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://oliverweber.com" target="_blank">OliverWeber</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://ultimainformatica.com" target="_blank">ÚltimaInformática</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://segundin.com" target="_blank">Segundín</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://todoespecias.com" target="_blank">TodoEspecias</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://licocosmetics.com" target="_blank">LicoCosmetics</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://chateohometasting.com" target="_blank">ChateoHometasting</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://solvinco.com" target="_blank">Solvinco</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://gruaslozano.com" target="_blank">GrúasLozano</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://dakadabags.com" target="_blank">DakadaBags</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://clandestinotshirt.com" target="_blank">ClandestinotShirt</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://kulturaltours.com" target="_blank">KulturalTours</Link></li>
                <li className={styles.separator}></li>
                <li><Link href="https://tustours.com" target="_blank">TusTours</Link></li>
                <li className={styles.separator}></li>
            </ul>
        </div>
    );
};

export default Clients;
