import React, {useLayoutEffect} from 'react';
import styles from "./services.module.css";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineShoppingCart } from "react-icons/md";
import { RiAppsLine } from "react-icons/ri";

const Services = () => {
    return (
        <div id="services" className={`${styles.services}`}>
            <div className={`${styles.headerServices} flex flex-row justify-between items-end text-white mx-auto mt-2 relative`}>
                <div>
                    <h2 className="leading-[48px] text-[40px] font-medium text-white">
                        Servicios: Desarrollo Web con<br/>
                        Soluciones Personalizadas
                    </h2>
                </div>
                <div className="leading-[40px]">
                    <h2 className="text-[40px] font-medium text-white">12 años</h2>
                    en desarrollo web
                </div>
            </div>
            <div className={`${styles.contentServices} flex flex-row justify-between gap-5 mt-5`}>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <CgWebsite />
                    </span>
                    <h3 className="font-bold mb-2">Sitios Web</h3>
                    <p>
                        Ofrecemos servicios de desarrollo web personalizados para crear sitios profesionales que reflejan la identidad de tu marca y cumplen con tus objetivos.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <MdOutlineShoppingCart/>
                    </span>
                    <h3 className="font-bold mb-2">E-commerce</h3>
                    <p>
                        Especializados en el desarrollo de tiendas online, ofrecemos soluciones completas para crear plataformas de e-commerce que maximizan tus ventas y mejoran la experiencia del cliente.
                    </p>
                </div>
                <div className={`${styles.serviceCard} bg-white p-5`}>
                    <span className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full`}>
                        <RiAppsLine/>
                    </span>
                    <h3 className="font-bold mb-2">Aplicaciones Web</h3>
                    <p>
                        Ofrecemos servicios de desarrollo de aplicaciones web personalizadas para resolver tus necesidades específicas y mejorar la eficiencia de tu negocio.
                    </p>
                </div>
            </div>
        </div>
);
};

export default Services;
